import { render, staticRenderFns } from "./Appointments.vue?vue&type=template&id=2bbaa248&scoped=true"
import script from "./Appointments.vue?vue&type=script&lang=js"
export * from "./Appointments.vue?vue&type=script&lang=js"
import style0 from "./Appointments.vue?vue&type=style&index=0&id=2bbaa248&prod&lang=scss"
import style1 from "../css/pages/appointments.scss?vue&type=style&index=1&id=2bbaa248&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bbaa248",
  null
  
)

export default component.exports