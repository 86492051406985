<template>
	<div id="top" class="reload-with-margin" :data-reload-margin="reloadMargin" data-reload-elem="toolbar,calendar-head">
		<div id="toolbar-wrapper" >
			<div ref="toolbar" id="toolbar">
				<WorkersDropdown ref="workersDropdown" :groups-selectable="true" v-on:change="$refs.calendar.workerChanged( $event )" v-on:group-change="$refs.calendar.groupChanged( $event )"/>
				<div class="check-btn">
					<input id="week" checked name="range" type="radio" value="week" v-on:click="$refs.calendar.daysChanged(7)"/>
					<label class="btn btn-secondary btn-checkable" for="week">{{$t("common.week")}}</label>
				</div>
				<div class="check-btn desktop">
					<input ref="day" id="day" name="range" type="radio" value="day" v-on:click="$refs.calendar.daysChanged(1)"/>
					<label class="btn btn-secondary  btn-day-mobile btn-checkable" for="day">{{$t("common.day")}}</label>
				</div>
				<div class="arrows">
					<div id="left-arrow-cont" v-on:click="$refs.calendar.dateLeft()">
						<svg id="left-arrow" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="51.73,7.46 44.26,0 12.26,32 44.26,64 51.73,56.53 27.2,32"/></svg>
					</div>
					<div id="right-arrow-cont" v-on:click="$refs.calendar.dateRight()">
						<svg id="right-arrow" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon points="19.73,0 12.26,7.46 36.8,32 12.26,56.53 19.73,64 51.73,32"/></svg>
					</div>
				</div>
				<div id="workers">
					<div class="workers-place" v-for="worker in workers.filter(w => !w.hidden)" :key="worker.id">
						<div class="color-legend" :style="'background: ' + worker.color"></div>
						<div class="name-legend">{{ worker.fname }}</div>
					</div>
				</div>
			</div>
		</div>
		<div ref="topSpacer" style="{ width: 100% }"></div>
		<div id="calendar-wrapper">
			<Calendar :fixedHeader="true" :allowEditing="true" :baseDaysCount="clientWidth < 768 ? 1 : 7" ref="calendar" @edit="showEditAppointmentModal = $event"/>
		</div>
		<!--div v-if="$store.state.worker.admin" class="cancel-all">
			<StatusButton ref="cancelAll" type="primary cancel-btn" @click="showConfirmDeleteAll( $event )">{{$t("administration.cancelall")}}</StatusButton>
		</div-->
		<Confirm ref="confirmDeleteAll" name="app-delete-confirm" v-if="cancelAllCallback" @confirm="cancelAll()" @close="cancelAllCallback(-1); cancelAllCallback = null; password = ''" :title="$t('common.cancel')">
			{{ $t('administration.cancelallconfirm') }}
			<div class="form-group input-group">
				<input class="form-control" type="password" :placeholder="$t('common.pwd')" v-model="password">
			</div>
		</Confirm>
		<NewAppointment v-if="showEditAppointmentModal" @confirm="showEditAppointmentModal=false" @close="showEditAppointmentModal=false" :edit="showEditAppointmentModal" name="newAppointment"/>
	</div>
</template>


<script>
	import Popper from 'vue-popperjs'
	import dayjs from 'dayjs'
	import api from '../apiClient'
	import Confirm from '../modals/Confirm.vue'
	import Calendar from '../components/Calendar.vue'
	import WorkersDropdown from '../components/WorkersDropdown.vue'
	import StatusButton from '../components/StatusButton.vue'
	import NewAppointment from '../modals/NewAppointment.vue'

	export default {
		components: {
			Confirm,
			Popper,
			Calendar,
			WorkersDropdown,
			StatusButton,
			NewAppointment
		},
		data() {
			return {
				reloadMargin: 0,
				cancelAllCallback: null,
				password: "",
				showEditAppointmentModal: false,
				resizeListener: () => this.resize(),
			}
		},
		computed: {
			workers() {
				return this.$store.getters.getWorkers()
			},
			clientWidth() {
				return document.documentElement.clientWidth
			}
		},
		watch: {
			password( value ) {
				if ( value ) {
					this.$refs.confirmDeleteAll.$refs.yes.disabled = false
				} else {
					this.$refs.confirmDeleteAll.$refs.yes.disabled = true
				}
			},
			'$route'( value ) {
				if ( value.path === "/appointments" ) {
					this.$nextTick( () => {
						if ( this.$route.query.workerid && this.$route.query.workerid > 0 ) {
							const workerid = parseInt(value.query.workerid) == this.$store.getters.getGeneralWorker().id ? -1 : parseInt(value.query.workerid)
							this.$refs.workersDropdown.setWorker( workerid )
							this.$refs.calendar.workerChanged( workerid )
						}
					})
				}
			},
		},
		methods: {
			resize() {
			//	if ( document.documentElement.clientWidth < 768 ) {
				//	this.$refs.day.click()
			//	}

				this.$nextTick( () => {
				//	this.reloadMargin = ( this.$refs.toolbar.offsetTop + this.$refs.toolbar.offsetHeight - this.$refs.calendar.$refs.head.clientHeight * 0.75 )
					this.$refs.calendar.$refs.head.style.top = ( this.$refs.toolbar.offsetTop + this.$refs.toolbar.offsetHeight ) + "px"
					//this.$refs.calendar.$el.style['margin-top'] = ( this.$refs.toolbar.offsetTop + this.$refs.toolbar.offsetHeight - this.$refs.calendar.$refs.head.clientHeight * 0.75 ) + "px"
					this.$refs.topSpacer.style['height'] = ( this.$refs.toolbar.offsetHeight - 2 ) + "px"
					Array.from(this.$refs.calendar.$refs.head.children[0].children).forEach( th => {
						th.style.top = ( this.$refs.toolbar.offsetTop + this.$refs.toolbar.offsetHeight - 1 ) + "px"
					})
				})
			},
			showConfirmDeleteAll( callback ) {
				this.cancelAllCallback = callback
				this.$nextTick( () => {
					this.$refs.confirmDeleteAll.$refs.yes.disabled = true
				})
			},
			cancelAll() {
				this.$api.appointments.patch( { password: this.password, "status[cat]": "canceled" }, { "date[gte]": dayjs(new Date()).format("YYYY-MM-DD") } ).then( response => {
				// this.$api.appointments.patch( { password: this.password, "status[cat]": "canceled" }, { "status": dayjs(new Date()).format("YYYY-MM-DD") } ).then( response => {
					this.password = ""
					this.cancelAllCallback( true )
					this.cancelAllCallback = null
				}).catch( error => {
					this.password = ""
					console.log( error )
					this.cancelAllCallback( false, error.response.data.error )
				})
			},
			update() {
				this.$refs.calendar.update()
			}
		},
		mounted() {
			this.resize()
			window.addEventListener("resize", this.resizeListener)
			this.$nextTick( () => {
				if ( this.$route.query.workerid && this.$route.query.workerid > 0 ) {
					const workerid = parseInt(this.$route.query.workerid) == this.$store.getters.getGeneralWorker().id ? -1 : parseInt(this.$route.query.workerid)
					this.$refs.workersDropdown.setWorker( workerid )
					this.$refs.calendar.workerChanged( workerid )
				}
			})
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.resizeListener)
		},
	}
</script>

<style lang="scss">
@import 'css/globals.scss';

body {
	@include mobile {
		padding-right: 0;
		padding-left: 0;
	}	
}
</style>

<style scoped lang="scss" src="../css/pages/appointments.scss"/>
